<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-16>
      <v-flex xs11 sm10>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >Ratings & Reviews
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center  v-if="seller.sellerId">
          <v-flex xs12 pt-3>
            <v-card flat>
              <v-layout wrap py-2>
                <v-flex xs3 sm3 md2>
                    <v-img
                      contain
                      height="160px"
                      width="100%"
                      :src="mediaUURL + seller.sellerId.profilePicture"
                    ></v-img>
                </v-flex>
                <v-flex xs9 sm9 md10 pl-3 align-self-center>
                  <v-layout wrap justify-start fill-height>
                    <v-flex xs12 text-left>
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 15px;
                          color: #191919;
                        "
                      >
                       {{ seller.sellerId.shopName }}
                      </span>
                    </v-flex>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm6 md3>
                        <v-layout wrap justify-start>
                          <v-flex xs12 pt-1 text-left v-if="seller.sellerId.address">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >{{ seller.sellerId.address.name }},
                              {{ seller.sellerId.address.place }},
                              {{ seller.sellerId.address.district }}
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-1 text-left>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Contact Number
                              <span style="font-family: poppinsregular"
                                >: {{ seller.sellerId.contactNumber }}</span
                              >
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 py-3>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 pb-2 text-left>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 15px;
                color: #191919;
              "
            >
              Rate this seller
            </span>
          </v-flex>
          <v-flex xs12 sm12 text-left>
            <v-rating
              color="#F28F34"
              background-color="#F28F34"
              half-increments
              size="30px"
              hover
              v-model="sellerRating"
            ></v-rating>
          </v-flex>
          <v-flex xs12 py-2 text-left>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 15px;
                color: #191919;
              "
            >
              Share your thoughts on this seller.
            </span>
          </v-flex>
          <v-flex xs12 sm12 text-left>
            <v-textarea outlined rows="2" v-model="sellerReview"> </v-textarea>
          </v-flex>
          <v-flex xs12 text-right align-self-end pb-5>
            <v-btn color="#30B868" dark @click="sellerData()"
              ><span style="font-family: opensanssemibold"
                >Post Review</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="products.length > 0">
          <v-flex xs12 pt-3 v-for="(item, i) in products" :key="i">
            <v-card flat>
              <v-layout wrap py-2>
                <v-flex xs3 sm3 md2 v-if="item.productId">
                  <v-card flat outlined>
                    <v-img
                      contain
                      height="160px"
                      width="100%"
                      :src="mediaUURL + item.productId.photos[0]"
                    ></v-img>
                  </v-card>
                </v-flex>
                <v-flex xs9 sm9 md10 pl-3 align-self-center>
                  <v-layout wrap justify-start fill-height>
                    <v-flex xs12 text-left v-if="item.productId">
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 15px;
                          color: #191919;
                        "
                      >
                        {{ item.productId.productname }}
                        <span v-if="item.itemId.size">
                          ({{ item.itemId.size }})
                        </span>
                      </span>
                    </v-flex>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm6 md3>
                        <v-layout wrap justify-start>
                          <!-- <v-flex xs12 pt-1 text-left v-if="item.category">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Category
                              <span style="font-family: poppinsregular"
                                >: {{ item.category }}
                              </span>
                            </span>
                          </v-flex> -->
                          <v-flex xs12 pt-1 text-left v-if="item.itemId">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Size
                              <span style="font-family: poppinsregular"
                                >: {{ item.itemId.size }}</span
                              >
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-1 text-left>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >OTY
                              <span style="font-family: poppinsregular"
                                >: {{ item.quantity }}
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6 md4 align-self-center>
                        <v-layout wrap align-center justify-start>
                          <v-flex xs12 sm12 md12 text-center>
                            <v-layout wrap justify-start>
                              <v-flex xs12 pb-5>
                                <span
                                  style="
                                    color: #30b868;
                                    font-family: RobotoMedium;
                                    font-size: 24px;
                                  "
                                  >₹ {{ item.itemId.offerPrice }}</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
            <v-flex xs12 py-3>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 pb-2 text-left>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 15px;
                color: #191919;
              "
            >
              Rate this product
            </span>
          </v-flex>
          <v-flex xs12 sm12 text-left>
            <v-rating
              color="#F28F34"
              background-color="#F28F34"
              half-increments
              size="30px"
              hover
              v-model="item.rating"
            ></v-rating>
          </v-flex>
          <v-flex xs12 py-2 text-left>
            <span
              style="
                font-family: poppinssemibold;
                font-size: 15px;
                color: #191919;
              "
            >
              Share your thoughts on this product.
            </span>
          </v-flex>
          <v-flex xs12 sm12 text-left>
            <v-textarea outlined rows="2" v-model="item.review"> </v-textarea>
          </v-flex>
          <v-flex xs12 text-right align-self-end pb-5>
            <v-btn color="#30B868" dark @click="productData(item)"
              ><span style="font-family: opensanssemibold"
                >Post Review</span
              ></v-btn
            >
          </v-flex>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-5 v-if="!seller.sellerId && !products.length > 0">
          <v-flex xs12 pa-2 align-self-center>
            <span
              style="
                font-family: poppinsregular;
                font-size: 18px;
                color: #30B868;
                cursor: pointer;
              "
              >Your feedback is successfully submitted</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 5,
      rating: "",
      review:"",
      sellerRating:"",
      sellerReview:"",
      searchKey: null,
      pincode: null,
      items: ["1", "2", "3", "4"],
      seller: [],
      products:[],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/details/feedback",
        data: {
            id:this.$route.query.orderId
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.seller = response.data.data;
          this.products=response.data.items;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerData(){
        this.appLoading = true;
      axios({
        method: "POST",
        url: "/feedback",
        data: {
          purchaseId: this.$route.query.orderId,
          sellerId:this.seller.sellerId._id,
          rating:this.sellerRating,
          review:this.sellerReview,
          type:"Seller"
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar=true
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });  
    },
    productData(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/feedback",
        data: {
          purchaseId: this.$route.query.orderId,
          rating: item.rating,
          review:item.review,
          itemId:item.itemId._id,
          type:"Product"
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar=true
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>